import './src/styles/slick.css'
import './src/styles/fonts.css'
import './src/styles/tailwind.css'

import React from 'react'
import { ApolloProvider } from '@apollo/client'

import CompareContext from './src/store/CompareContext'
import FavoriteContext from './src/store/FavoriteContext'
import { client } from './src/apollo/client'

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <CompareContext>
      <FavoriteContext>{element}</FavoriteContext>
    </CompareContext>
  </ApolloProvider>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
