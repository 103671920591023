import React, { useEffect, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/core'

const CompareContext = createContext()
const CompareActionsContext = createContext()

export const useCompareContext = () => useContext(CompareContext)
export const useCompareActionsContext = () => useContext(CompareActionsContext)

const CompareContextProvider = ({ children }) => {
  const toast = useToast()
  const [compareItems, setCompareItems] = useState(() =>
    typeof window === `object` && window.localStorage.getItem(`compareItems`)
      ? JSON.parse(window.localStorage.getItem(`compareItems`))
      : []
  )

  function addCompareItem(property) {
    const hasItem =
      compareItems.findIndex(
        (item) => item.FullAddress === property.FullAddress
      ) !== -1

    if (hasItem) {
      toast({
        title: `An error occurred.`,
        description: `You already added this property.`,
        status: `error`,
        duration: 9000,
        isClosable: true,
      })
    } else if (compareItems.length > 2) {
      toast({
        title: `An error occurred.`,
        description: `You can only compare three properties.`,
        status: `error`,
        duration: 9000,
        isClosable: true,
      })
    } else {
      const items = [...compareItems, property]

      setCompareItems(items)
    }
  }

  function removeCompareItem(FullAddress) {
    const items = compareItems.filter(
      (item) => item.FullAddress !== FullAddress
    )

    setCompareItems(items)
  }

  useEffect(() => {
    if (typeof window === `object`)
      window.localStorage.setItem(`compareItems`, JSON.stringify(compareItems))
  }, [compareItems])

  return (
    <CompareContext.Provider value={compareItems}>
      <CompareActionsContext.Provider
        value={{ addCompareItem, removeCompareItem }}
      >
        {children}
      </CompareActionsContext.Provider>
    </CompareContext.Provider>
  )
}

CompareContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CompareContextProvider
