module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-chakra-ui/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":false,"isUsingColorMode":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://admin.auctionhouselondon.co.uk/graphql","verbose":true,"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false,"hardCacheData":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":false,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"CoreParagraphBlockAttributesV2":{"exclude":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-82073113-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2M4N5H","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Auction House London","short_name":"AHL","description":"London Property Auctions Residential & Commercial Auction House London","lang":"en","display":"standalone","icon":"src/images/icon.png","start_url":"/","background_color":"#c8102e","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7b04e5d479082e5842563395e4bfddd6"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
