import React, { useEffect, useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/core'

const FavoriteContext = createContext()
const FavoriteActionsContext = createContext()

export const useFavoriteContext = () => useContext(FavoriteContext)
export const useFavoriteActionsContext = () =>
  useContext(FavoriteActionsContext)

const FavoriteContextProvider = ({ children }) => {
  const toast = useToast()
  const [favoriteItems, setFavoriteItems] = useState(() =>
    typeof window === `object` && window.localStorage.getItem(`favoriteItems`)
      ? JSON.parse(window.localStorage.getItem(`favoriteItems`))
      : []
  )

  function checkFavoriteItem(property) {
    return favoriteItems.findIndex((item) => item.ID === property.ID) !== -1
  }

  function addFavoriteItem(property) {
    const hasItem =
      favoriteItems.findIndex((item) => item.ID === property.ID) !== -1

    if (hasItem) {
      toast({
        title: `An error occurred.`,
        description: `You already added this property.`,
        status: `error`,
        duration: 9000,
        isClosable: true,
      })
    } else {
      const items = [...favoriteItems, property]

      setFavoriteItems(items)
    }
  }

  function removeFavoriteItem(property) {
    const items = favoriteItems.filter((item) => item.ID !== property.ID)

    setFavoriteItems(items)
  }

  useEffect(() => {
    if (typeof window === `object`)
      window.localStorage.setItem(
        `favoriteItems`,
        JSON.stringify(favoriteItems)
      )
  }, [favoriteItems])

  return (
    <FavoriteContext.Provider value={favoriteItems}>
      <FavoriteActionsContext.Provider
        value={{ addFavoriteItem, removeFavoriteItem, checkFavoriteItem }}
      >
        {children}
      </FavoriteActionsContext.Provider>
    </FavoriteContext.Provider>
  )
}

FavoriteContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FavoriteContextProvider
